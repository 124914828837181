<template>
  <div
    class="flex px-5 min-w-0 relative border-b"
    :class="{ 'cursor-pointer': isRowClickable }"
    @click="openModal"
  >
    <div
      class="space-x-8 border-r py-5 flex items-center justify-between sticky left-0 h-[5.3rem] bg-white z-10 shadow-[2px_0_4px_-2px_rgba(0,0,0,0.1)] w-110 flex-wrap"
    >
      <div
        class="w-max"
        v-if="(!singleSelect && !multiSelect) || !canUpdateOffer"
      >
        {{ offerDetail.details.offer_template.name }}
      </div>
      <lf-checkbox
        v-if="multiSelect && canUpdateOffer"
        v-model="isOfferSelected"
        :value="offerDetail"
        name="offer-checkbox"
        class="w-max"
      >
        {{ offerDetail.details.offer_template.name }}
      </lf-checkbox>
      <template v-else-if="singleSelect && selectOneKey">
        <input
          type="radio"
          name="selectOne"
          v-model="isOfferSelected"
          :value="`${offerDetail.id}_${offerDetail[selectOneKey]}`"
        />
        <table-select-details
          v-if="typeof selectDetailsProvider === 'function'"
          :options="selectDetailsProvider(offerDetail)"
        />
        <component
          :is="selectDetailsProvider"
          v-else-if="typeof selectDetailsProvider === 'object'"
          :data="offerDetail"
        />
      </template>
      <div class="flex pr-3 mx-2">
        <offer-shared
          v-if="!isClosing && !viewOnly && canUpdateOffer"
          :offer-details="offerDetail"
          class="mt-1"
        />
        <offer-note-prompt
          v-if="!isClosing && !viewOnly && canUpdateOffer"
          :offer-detail="offerDetail"
          :funder="funder"
          :placement="placement"
          :is-closing="isClosing"
        />
        <div class="ml-2 mt-0.5">
          <offer-status-pill
            v-if="viewOnly || isClosing || !canUpdateOffer"
            :model-value="offerDetail.status"
          />
          <offer-pill-dropdown
            v-else
            :value="offerDetail.status ?? ''"
            :model="offerDetail"
            :model-value="offerDetail.status ?? ''"
          />
        </div>
      </div>
    </div>
    <div class="flex min-w-0 w-full h-[5.3rem] ml-15">
      <div class="flex items-center">
        <div class="grid auto-cols-fr grid-flow-col gap-4 min-w-0 w-full">
          <wfl-offer-field-display
            class="min-w-[200px]"
            v-for="field in offerFields"
            :offer-field="field"
            :key="field.name"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!viewOnly && canUpdateOffer"
      class="fixed-dropdown pl-2 bg-white z-10 border-l flex items-center justify-center"
      @click.stop
    >
      <offer-dropdown-menu :model="offerDetail" :is-closing="isClosing" />
    </div>
  </div>
  <offer-modal
    v-if="showOfferModal"
    :offer-details="[offerDetail.details]"
    edit-mode
    :is-closing="isClosing"
    :hide-offer-tab="isFunded"
    :view-only="viewOnly"
    @close="showOfferModal = false"
  />
</template>
<script setup lang="ts" generic="T extends IOffer">
import type { IFunder, ILenderPlacement, IOffer } from "@/models/funders";
import LfCheckbox from "@/components/ui/inputs/LfCheckbox.vue";
import OfferShared from "@/components/ui/OfferShared.vue";
import OfferNotePrompt from "@/views/deals/components/OfferNotePrompt.vue";
import { computed, ref } from "vue";
import OfferPillDropdown from "@/components/ui/OfferPillDropdown.vue";
import WflOfferFieldDisplay from "@/components/deals/workflow/OfferStage/WorkflowLive/WflOfferFieldDisplay.vue";
import OfferDropdownMenu from "@/views/deals/components/OfferDropdownMenu.vue";
import OfferStatusPill from "@/components/ui/OfferStatusPill.vue";
import OfferModal from "@/views/deals/components/OfferModal.vue";
import TableSelectDetails from "@/components/ui/table/TableSelectDetails.vue";
import type { SelectDetailsProvider } from "@/models/common";
import type { OfferFieldDetails } from "@/models/offers";
import { usePermissions } from "@/hooks/auth";
import { Ability, PermissionSubject } from "@/enums/auth";

const props = defineProps<{
  offerDetail: T;
  funder?: IFunder;
  placement: ILenderPlacement;
  isRowClickable?: boolean;
  isClosing?: boolean;
  isFunded?: boolean;
  multiSelect?: boolean;
  singleSelect?: boolean;
  selectOneKey?: keyof T;
  selectDetailsProvider?: SelectDetailsProvider;
  viewOnly?: boolean;
  isSelected?: boolean;
}>();

const emit = defineEmits<{
  selectionChange: [IOffer, boolean];
}>();

const { canPerformActionReactive } = usePermissions();

const canUpdateOffer = canPerformActionReactive(
  PermissionSubject.offer,
  Ability.update,
  { offer: props.offerDetail.id }
);

const showOfferModal = ref(false);
const isOfferSelected = computed({
  get: () => props.isSelected || false,
  set: (value) => {
    emit("selectionChange", props.offerDetail, value);
  }
});

const offerFields = computed(() => {
  const dataSelector = props.isClosing ? "closing_details" : "offer_details";

  return props.offerDetail[dataSelector].reduce(
    (acc: OfferFieldDetails[], field) => {
      // Skip note fields
      if (field.name === "note") {
        return acc;
      }

      // For closing view with no closing details, use offer details values
      if (props.isClosing && !props.offerDetail.has_closing_details) {
        const offerField = props.offerDetail?.offer_details.find(
          (offerField) => offerField.name === field.name
        );

        if (offerField) {
          acc.push({ ...field, value: offerField.value });
        }
        return acc;
      }

      acc.push(field);
      return acc;
    },
    []
  );
});

const openModal = () => {
  if (props.isRowClickable) {
    showOfferModal.value = true;
  }
};
</script>
<style scoped>
.grid {
  grid-auto-columns: minmax(200px, 1fr);
}

.dropdown-wrapper {
  position: relative;
}

.fixed-dropdown {
  position: sticky;
  right: 0;
  z-index: 10;
}
</style>
